import RouteViewComponent from "./routerView";

const routeAccountReceivable = [
  {
    path: "test",
    name: "ar_payment_test",
    // meta: { url: "/", parent: "" },
    // redirect: "/pages/login"
    component: () => import("@/views/oms/finance/ar_payment/index.vue"),
  },
  {
    path: "payment-received",
    name: "ar_payment_payment_received",
    component: RouteViewComponent,
    children: [
      {
        path: "/",
        name: "ar_payment_payment_received.index",
        component: () =>
          import("@/views/oms/finance/ar_payment/payment-received.vue"),
      },
      {
        path: "create",
        name: "ar_payment_payment_received.create",
        component: () =>
          import("@/views/oms/finance/ar_payment/payment-received/form.vue"),
      },
      {
        path: "form/:id",
        name: "ar_payment_payment_received.form",
        component: () =>
          import("@/views/oms/finance/ar_payment/payment-received/form.vue"),
      },
      {
        path: "edit/:id",
        name: "ar_payment_payment_received.edit",
        component: () =>
          import("@/views/oms/finance/ar_payment/payment-received/form.vue"),
      },
    ],
    // meta: { url: "/", parent: "" },
    // redirect: "/pages/login"
  },
  {
    path: "cn-temporary",
    name: "cn_temporary",
    component: RouteViewComponent,
    children: [
      {
        path: "",
        name: "cn_temporary.index",
        component: () =>
          import("@/views/oms/finance/credit_note_temporary/index.vue"),
      },
      {
        path: "create",
        name: "cn_temporary.create",
        component: () =>
          import("@/views/oms/finance/credit_note_temporary/form.vue"),
      },
      {
        path: "view/:id",
        name: "cn_temporary.view",
        component: () =>
          import("@/views/oms/finance/credit_note_temporary/form.vue"),
      },
      {
        path: "edit/:id",
        name: "cn_temporary.edit",
        component: () =>
          import("@/views/oms/finance/credit_note_temporary/form.vue"),
      },
    ],
  },
];

export default routeAccountReceivable;
