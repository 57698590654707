/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import moduleAuth from "./auth/moduleAuth.js";

// per module
import promotion from "./modules/promotion.js";
import user from "./modules/user";
import arClearing from "./modules/ar-clearing.js";
import arPaymentReceive from "./modules/ar-payment-receive.js";
import operatingUnit from "./modules/operatingUnit.js";
import clearingDN from "./modules/clearingDn.js";
import customerFilter from "./modules/customer.js";

Vue.use(Vuex);

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    promotion: promotion,
    user: user,
    auth: moduleAuth,
    clearingDN: clearingDN,
    arClearing,
    arPaymentReceive,
    operatingUnit: operatingUnit,
    customerFilter: customerFilter,
  },
  strict: process.env.NODE_ENV !== "production",
});
