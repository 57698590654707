const state = {
  selectedCreditNote: [],
  selectedCustomerDeposit: [],
  selectedInvoice: [],
  invoicePaidValue: [],
  selectedCustomer: {},
  radioFilterValue: "cn",
  postingDate: {
    date: null,
  },
  paymentDate: {
    date: null,
  },
  tabIndex: 0,
  isDataVisible: false,
};

const mutations = {
  setCustomer(state, value) {
    state.selectedCustomer = value;
  },
  setCreditNote(state, value) {
    state.selectedCreditNote = value;
  },
  setCustomerDeposit(state, value) {
    state.selectedCustomerDeposit = value;
  },
  setInvoice(state, value) {
    state.selectedInvoice = value;
  },
  setinvoicePaidValue(state, value) {
    state.invoicePaidValue = value;
  },
  resetCreditNote(state) {
    state.selectedCreditNote = [];
  },
  resetCustomerDeposit(state) {
    state.selectedCustomerDeposit = [];
  },
  resetInvoice(state) {
    state.selectedInvoice = [];
  },
  resetSelectedCnDn(state) {
    state.selectedCreditNote = [];
    state.selectedCustomerDeposit = [];
    state.isDataVisible = false;
  },
  resetState(state) {
    state.selectedCreditNote = [];
    state.selectedCustomerDeposit = [];
    state.selectedInvoice = [];
    state.selectedCustomer = {};
    state.radioFilterValue = "cn";
    state.postingDate = {
      date: null,
    };
    state.paymentDate = {
      date: null,
    };
    state.tabIndex = 0;
    state.isDataVisible = false;
    state.invoicePaidValue = [];
  },
  setRadioFilter(state, value) {
    state.radioFilterValue = value;
  },
  setPaymentDate(state, value) {
    state.paymentDate.date = value;
  },
  setPostingDate(state, value) {
    state.postingDate.date = value;
  },
  setTabIndex(state, value) {
    state.tabIndex = value;
  },
  setDatavisibility(state, value) {
    state.isDataVisible = value;
  },
};

const getters = {
  getSelectedCreditNotesIds(state) {
    return state.selectedCreditNote.map((cn) => cn.ID);
  },
  getSelectedCustomerDepositsIds(state) {
    return state.selectedCustomerDeposit.map((cd) => cd.ID);
  },
  getSelectedInvoiceIds(state) {
    return state.selectedInvoice.map((inv) => inv.ID);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
