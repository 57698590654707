
export default {
  prefix: '',
  suffix: '',
  separator: ',',
  decimal: '.',
  precision: 2,
  minimumFractionDigits: null,
  prefill: true,
  reverseFill: false,
  min: null,
  max: null,
  nullValue: '0.00'
}
