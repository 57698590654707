import api from "@/axios.js";
import Vue from "vue";

const state = {
  filter: {
    territory: [],
    territory_single: [],
    operatingUnit: [],
    selectedTerritories: [
      { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
    ],
    selectedTerritory: {},
    selectedOperatingUnits: [{ ID: 0, Code: "All", Name: "All" }],
  },
  whiteListUrl: [
    "cash-bank-management",
    "payment",
    "billing",
    "credit-note",
    "approval-cash-deposit",
    "approval-cash-deposit-reversal",
    "pod processing",
    "invoice exchange",
    "customer-deposit",
    "bank-statement",
    "invoice exchange edit",
    "payment",
    "credit-limit-request",
    "ar_payment_payment_received",
    "Payment",
    "bank-statement",
    "dn-with-or-without-po-ref",
    "create-credit-note-manual",
    "edit-credit-note-manual",
  ],
};

const mutations = {
  setTerritoryFilter(state, payload) {
    state.filter.territory = JSON.parse(JSON.stringify(payload));
  },
  setOperatingUnitFilter(state, payload) {
    state.filter.operatingUnit = JSON.parse(JSON.stringify(payload));
  },
  setTerritoryFilterSingle(state, payload) {
    state.filter.territory_single = JSON.parse(JSON.stringify(payload));
  },
  setTerritoryFilterByOperatingUnits(state) {
    let territory = JSON.parse(JSON.stringify(state.filter.operatingUnit));
    territory.shift(); //remove "all" object from operating unit
    const singleTerritory = territory;
    territory = [
      { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
      ...territory,
    ];

    if (state.filter.selectedOperatingUnits.some((ou) => ou.ID === 0)) {
      state.filter.territory = territory;
      state.filter.territory_single = singleTerritory;
    } else {
      const filtered = territory.filter((ou) =>
        state.filter.selectedOperatingUnits.some((sou) => sou.ID === ou.ID)
      );
      state.filter.territory = filtered;
      state.filter.territory_single = filtered;
    }
  },
  setSelectedTerritories(state, payload) {
    state.filter.selectedTerritories = JSON.parse(JSON.stringify(payload));
  },
  setSelectedTerritory(state, payload) {
    state.filter.selectedTerritory = payload;
  },
  setSelectedOperatingUnits(state, payload) {
    state.filter.selectedOperatingUnits = JSON.parse(JSON.stringify(payload));
  },
  resetState(state) {
    state.filter.selectedTerritories = [
      { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
    ];
    state.filter.selectedOperatingUnits = [{ ID: 0, Code: "All", Name: "All" }];
    state.filter.selectedTerritory = {};
  },
};

const actions = {
  async getOperatingUnitWithTerritory({ commit }, params) {
    try {
      // Vue.$vs.loading();
      showLoading();
      const response = await api.get(
        "/api/v1/master/operating-unit-with-territory/personal",
        {
          params
        }
      );
      const operatingUnit = response.data.records;
      commit("setOperatingUnitFilter", [
        { ID: 0, Code: "All", Name: "All" },
        ...operatingUnit,
      ]);
      commit("setTerritoryFilter", [
        { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
        ...operatingUnit,
      ]);
      commit("setTerritoryFilterSingle", operatingUnit);
      hideLoading();
    } catch (error) {
      hideLoading();
      console.error(error);
      throw error;
    }
  },
};

const getters = {
  getSelectedTerritoryId(state) {
    if (
      state.filter.selectedTerritories.some((st) => st.TerritoryName === "All")
    ) {
      const territories = JSON.parse(JSON.stringify(state.filter.territory));
      if (territories[0].TerritoryName === "All") {
        territories.shift();
      }
      return territories.map((t) => t.TerritoryID);
    } else {
      return state.filter.selectedTerritories.map((t) => t.TerritoryID);
    }
  },
  getSelectedTerritoryIdSingle(state) {
    return state.filter.selectedTerritory.TerritoryID;
  },
};

function showLoading() {
  Vue.prototype.$vs.loading();
}

function hideLoading() {
  Vue.prototype.$vs.loading.close();
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
