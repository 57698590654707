const formInitialState = () => {
  return {
    tabIndex: 0
  };
};

const initialState = () => {
  return {
    isEdit: true,
    tabIndex: 0,
    promotionId: null,
    form: formInitialState()
  };
};

const namespaced = true;

const state = initialState;

const getters = {};

const actions = {
  setFormObj({ commit }, { name, data }) {
    commit("updateFormObj", { name, data });
  },
  setIsEdit({ commit }, data) {
    commit("updateIsEdit", data);
  },
  setTabIndex({ commit }, data) {
    commit("updateTabIndex", data);
  },
  setPromotionId({ commit }, data) {
    commit("updatePromotionId", data);
  },
  setInitialState({ commit }) {
    commit("updateInitialState", initialState());
  }
};

const mutations = {
  updateFormObj(state, { name, data }) {
    state.form[name] = data;
  },
  updateIsEdit(state, data) {
    state.isEdit = data;
  },
  updateTabIndex(state, data) {
    state.tabIndex = data;
  },
  updatePromotionId(state, data) {
    state.promotionId = data;
  },
  updateInitialState(state, data) {
    state = data;
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
